.content {
  display: flex;
  flex-direction: column;
  max-width: 384px;
  gap: 12px;
  width: 100%;
}

.helpContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 32px 0;
  padding-bottom: 80px;
}
.link {
  color: var(--Primitive-Blue-500);
  text-decoration: underline;
  font-weight: 500;
}
.bold {
  font-weight: 700;
}
.cards {
  padding-top: 8px;
  position: relative;
  width: 100%;
}
.card {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 24px;

  border-radius: var(--radius);
  border: var(--stroke-0, 1px) solid var(--Primitive-200);
  background: var(--Primitive-0);

  box-shadow: 0px 8px 12px -2px rgba(14, 15, 57, 0.06),
    0px 4px 3px -1px rgba(14, 15, 57, 0.02);
  padding: 20px;
  padding-bottom: 40px;
  height: 100%;
  width: 100%;
}
.titleAndInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.icon {
  max-width: 64px;
  width: 100%;
  border-radius: 50%;
}
@media only screen and (max-width: 991px) {
  .content {
    max-width: 600px;
    width: 100%;
  }

  .helpContainer {
    padding: 24px 0;
  }
}
@media only screen and (max-width: 520px) {
  .card {
    padding: 16px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title,
  .titleAndInfo .info {
    text-align: center;
  }
}
