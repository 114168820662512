.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 348px;
  gap: 12px;
  align-items: flex-end;
  padding: 80px 0;
}
.box,
.growthContainer,
.logoContainer,
.boxs {
  max-height: 348px;
}
.infoWrapper {
  grid-column: span 2;
}
.infoContiner {
  max-width: 524px;
  width: 100%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.buttonContainer .joinNow {
  padding: 4px 4px 4px 24px;
}
.linkContainer {
  width: 44px;
  height: 44px;
  border-radius: 4px;
  background: var(--Primitive-0);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Primitive-950);
  font-size: 24px;
}
.smallBox {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background: var(--Primitive-950);
  margin-left: auto;
}
.box {
  padding: 48px;
  border-radius: var(--radius);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  max-width: 255px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.ourCommunity {
  display: flex;
  flex-direction: column;

  gap: 8px;
  background: var(--Primitive-Blue-500);
}
.avatars {
  display: flex;
  align-items: center;
  margin-top: auto;
}
.avatarContainer {
  max-width: 66px;
  width: 100%;
  background-color: var(--Primitive-Blue-500);
  border-radius: 50%;
  padding-top: 24px;
}
.avatarContainer img,
.avatar {
  width: 100%;
  border-radius: 50%;
  border: 6px solid var(--Primitive-Blue-500);
  mix-blend-mode: luminosity;
}
.logoContainer {
  background: url(../../../../images/vector.png), var(--Primitive-950);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.logo {
  max-width: 112px;
  width: 100%;
}
.testimonial {
  background: var(--Primitive-100);
}
.testimonial .content {
  gap: 36px;
}
.avatar {
  max-width: 88px;
  border: none;
}
.growthContainer {
  height: 100%;
}
.growthImg {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}
.cryptoSupported {
  background: var(--Primitive-950);
}
.mtAuto {
  margin-top: auto;
}
.boxs {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
}

.boxImg {
  max-width: 188px;
  width: 100%;
}
@media only screen and (max-width: 1440px) {
  .box {
    padding: 32px;
  }
}
@media only screen and (max-width: 1199px) {
  .container {
    grid-template-columns: 1fr 1fr 1fr;
    width: 90%;
    margin: 0 auto;
  }
  .infoContiner {
    margin: 0;
  }
  .growthContainer,
  .boxs {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .container {
    grid-template-columns: 1fr 1fr;
    padding: 48px 0;
  }
  .infoWrapper {
    grid-column: span 1;
  }
  .logoContainer {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .testimonial,
  .cryptoSupported {
    display: none;
  }
  .box {
    padding: 24px;
  }
  .box {
    max-height: auto;
  }
}
@media only screen and (max-width: 520px) {
  .smallBox {
    width: 48px;
    height: 48px;
  }
}
