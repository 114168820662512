.wrapper {
  padding: 160px 0;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.hr {
  height: 1px;
  background: var(--Primitive-950);
}
.heading {
  max-width: 560px;
  width: 100%;
}
.content {
  display: grid;
  grid-template-columns: auto auto;
  gap: 48px;
}
.info {
  max-width: 628px;
}
.imgContainer {
  max-width: 524px;
  width: 100%;
}
.img {
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding: 96px 0;
  }
  .container {
    gap: 48px;
  }
  .content {
    grid-template-columns: 1fr;
  }
  .info {
    max-width: 100%;
  }
  .imgContainer {
    margin: 0 auto;
  }
}
