.wrapper {
  padding: 24px;
  border-radius: var(--radius);
  background: var(--Primitive-0);
  box-shadow: 0px 1px 2px 0px rgba(14, 15, 57, 0.04);
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.myTradingView {
  align-items: center;
  width: 100%;
}
.container {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 32px;
}
.priceInfo {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.priceContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.price {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}
.up,
.down {
  display: flex;
  align-items: start;
  gap: 2px;
}
.up {
  color: var(--Primitive-Green-500);
}
.down {
  color: #ff4864;
}
.up .indicator {
  font-size: 18px;
  color: var(--Primitive-Green-500);
}
.down .indicator {
  font-size: 18px;
  color: #ff4864;
}
.marketInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
}
.key {
  white-space: nowrap;
}
.value {
  margin-left: auto;
}
.hr {
  width: 1px;
  background: var(--Primitive-100);
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .container {
    grid-template-columns: 1fr;
    gap: 24px;
  }
  .hr {
    height: 1px;
    width: 100%;
  }
}
