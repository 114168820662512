.wrapper {
  border-radius: var(--radius);
  background: var(--Primitive-950);
  padding: 96px 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
@media only screen and (max-width: 1280px) {
  .wrapper {
    padding: 70px 48px;
  }
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    padding: 60px 48px;
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 24px;
  }
}
