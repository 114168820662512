.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.border {
  width: 100%;
  border-bottom: 1px solid var(--Primitive-200);
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;

  padding-top: 24px;
  border-top: 1px solid var(--Primitive-200);
  width: 100%;
}
.button {
  margin-left: auto;
}
