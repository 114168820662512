.wrapper {
  padding-top: 95px;
}
.outlet {
  display: grid;
  align-items: center;
  padding: 80px 0;
  padding-top: 24px;
  min-height: calc(100vh - 192px);
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding-top: 80px;
  }
  .outlet {
    display: block;
    padding: 48px 0;
    padding-top: 24px;
    min-height: auto;
  }
}
