.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inputs {
  border-radius: 4px;
  background-color: var(--Primitive-0);
}
.noResendInputs {
  background: var(--Primitive-0);
}

.input {
  padding: 18px;
  text-align: center;
  font-size: 24px;
  outline: none;
  color: var(--Primitive-700);
}

.inputs > * {
  justify-content: center;
  gap: 0;
}

.input {
  width: 50px !important;
  height: 50px !important;
  margin-right: 8px !important;
  padding: 0 !important;
}
.input:last-child {
  margin: 0 !important;
}
.noResendInputs .input {
  border: 1px solid var(--Primitive-200);
  border-radius: 4px;
}

@media screen and (max-width: 520px) {
  .input {
    width: 40px !important;
    height: 40px !important;
  }
}

@media screen and (max-width: 360px) {
  .input {
    width: 30px !important;
    height: 30px !important;
    margin-right: 4px !important;
  }
}

.hasError {
  border-color: var(--Error);
}

.helperError {
  color: var(--Error);
  font-size: 16px;
}

/* .inputs */
input::placeholder {
  color: var(--Text-Placeholder);
  opacity: 0.5;
}

.actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  width: 100%;
}
/* .noResend {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
  padding-top: 16px;
} */

/* .actions:not(.fullWidth) > * {
  justify-content: center;
  align-items: center;
  display: flex;
} */

/* .actions.fullWidth > * {
  width: 100%;
  justify-content: center;
} */
.noResend {
  grid-template-columns: 1fr;
  width: 100%;
}
.resend {
  background-color: transparent;
  color: var(--Primitive-Blue-500);
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
}

.resend:disabled {
  opacity: 0.5;

  cursor: not-allowed;
}
.notAllowed {
  cursor: not-allowed;
}
.notAllowed:hover {
  transform: translateY(0);
}
