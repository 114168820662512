.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  width: 100%;
  max-width: 338px;
  margin: 0 auto;
}
.button {
  padding: 0;
  background: transparent;
  font-size: 16px;
}
.cancelButton {
  color: var(--Primitive-400);
}
.backButton {
  color: var(--Primitive-Blue-500);
}
