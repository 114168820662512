.container {
  padding: 80px 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 848px;
  width: 100%;
  margin: 0 auto;
}
.info {
  max-width: 625px;
  width: 100%;
}
.content {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 64px;
}
.stepContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .header {
    max-width: 600px;
  }
  .content {
    display: flex;
    flex-direction: column-reverse;
  }
  .container {
    padding: 64px 0;
    padding-bottom: 0;
    gap: 48px;
  }
}
