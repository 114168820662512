.wrapper {
  padding: 160px 0;
  display: flex;
  flex-direction: column;
  gap: 96px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 620px;
  width: 100%;
}

.buttonContainer {
  display: flex;
  align-items: center;
}
.imgContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.img {
  max-width: 500px;
  width: 100%;
}

.counter {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 48px;
  padding: 48px 0;
  border-top: 1px solid var(--Primitive-400);
  border-bottom: 1px solid var(--Primitive-400);
}
.infoBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
@media only screen and (max-width: 1199px) {
  .counter {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 991px) {
  .wrapper {
    padding: 64px 0;
    gap: 64px;
  }
  .container {
    grid-template-columns: 1fr;
    gap: 32px;
  }
  .imgContainer {
    justify-content: center;
  }
}
@media only screen and (max-width: 520px) {
  .counter {
    grid-template-columns: 1fr;
  }
}
