.wrapper {
  padding: 24px;
  border-radius: var(--radius);
  background: var(--Primitive-0);
  box-shadow: 0px 1px 2px 0px rgba(14, 15, 57, 0.04);
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.linkWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
}
.linkContainer {
  padding: 10px 16px;
  border-radius: 4px;
  border: 1px solid var(--Primitive-200);
  background: var(--Primitive-0);
}
.link {
  white-space: nowrap;
  max-width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}
.buttonContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
}
.qrCodeContainer {
  padding: 10px 12px;
  border-radius: 6px;
  max-width: 48px;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Primitive-Green-500);
}
.qrCodeIcon {
  max-width: 24px;
  width: 100%;
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 16px;
  }
}
