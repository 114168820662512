.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.balanceAndInvestmentDetails {
  display: grid;
  grid-template-columns: 305px 1fr;
  gap: 20px;
}

.investmentAndOverview {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
@media only screen and (max-width: 1199px) {
  .balanceAndInvestmentDetails {
    grid-template-columns: 1fr;

    width: 100%;
  }
  .investmentAndOverview {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }
}
