.wrapper {
  height: 100%;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 1199px) {
  .container {
    gap: 32px;
  }
}
@media only screen and (max-width: 991px) {
  .container {
    display: block;

    max-width: 750px;
    margin: 0 auto;
  }
}
