.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.header {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
  align-items: flex-end;
  padding: 12px;
  background: var(--Primitive-50);
  border-radius: 6px;
}
.note {
  color: var(--Primitive-800);
  font-weight: 500;
}

.plan {
  padding: 2px 8px;
  border-radius: 4px;
  background: var(--Primitive-Blue-500);
  color: var(--Primitive-0);
  line-height: 150%;
  font-size: 14px;
  text-transform: uppercase;
}
.depositInfo {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid var(--Primitive-100);
}
.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.value {
  margin-left: auto;
  text-transform: capitalize;
}
.active {
  color: var(--Primitive-Green-500);
}
.completed {
  background: #0ad1c8;
}
.failed {
  background: #ff4864;
}
.pending {
  background: #f59e0b;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.policyContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
}
.link {
  color: var(--Primitive-Blue-500);
  font-weight: 500;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;

  padding-top: 24px;
  border-top: 1px solid var(--Primitive-200);
  width: 100%;
}
.button {
  color: var(--Primitive-500);
}
@media only screen and (max-width: 520px) {
  .amountInput label {
    font-size: 16px;
  }
}
