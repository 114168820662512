.formContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 348px;
  width: 100%;
  margin: 0 auto;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.allReadyHave {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.link {
  font-weight: 500;
  color: var(--Primitive-Blue-500);
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.or {
  position: relative;

  padding: 12px;

  width: 100%;
}
.or::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 43%;
  height: 1px;
  transform: translateY(-50%);
  background: var(--Primitive-100);
}
.or::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 43%;
  height: 1px;
  transform: translateY(-50%);
  background: var(--Primitive-100);
}
.buttonContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 6px;
}
.logo {
  font-size: 24px;
}
.appleLogo {
  color: #000;
}

@media only screen and (max-width: 991px) {
  .formContainer {
    padding-top: 48px;
  }
}
