.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.withdraw {
  color: var(--Error);
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 8px 16px;
}
.key {
  white-space: nowrap;
}
.value {
  margin-left: auto;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.withdraw {
  color: #ff4864;
  text-transform: capitalize;
}
.deposit {
  color: #22c55e;
  text-transform: capitalize;
}
.status {
  padding: 2px 8px;
  border-radius: 4px;
  text-transform: capitalize;
}
.completed {
  background: #22c55e;
}
.failed {
  background: #ff4864;
}
.pending {
  background: #f59e0b;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;

  padding-top: 24px;
  border-top: 1px solid var(--Primitive-200);
  width: 100%;
}
.button {
  margin-left: auto;
}
