.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.phoneNumberContainer {
  display: grid;
  grid-template-columns: 112px 1fr;
  gap: 8px;
}
.dropdownItem {
  padding: 10px 16px;
  border-radius: 4px;
  border: 1px solid var(--Primitive-200);
  background: var(--Primitive-0);
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}
.arrow {
  font-size: 16px;
  color: var(--Placeholder);
}
.code {
  font-size: 16px;

  color: var(--Primitive-700);
  line-height: 150%;
  font-weight: 500;
}
