.wrapper {
  background: var(--Primitive-950);
}
.container {
  display: grid;
  grid-template-columns: auto auto auto auto;

  gap: 96px;
  padding: 64px 0;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 28px;
  max-width: 327px;
  width: 100%;
}
.socialContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}
.icon {
  font-size: 20px;
  color: var(--Primitive-300);
}
.itemsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.link {
  color: var(--Primitive-200);

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.addressContainer {
  max-width: 240px;
  width: 100%;
}
.copyRightContainer {
  border-top: 1px solid rgba(114, 115, 157, 0.24);
  padding: 32px 0;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 24px;
  align-items: center;
}
.brandName {
  font-weight: 500px;
}
.logoContainer {
  display: flex;
  justify-content: center;
}
.logo {
  max-width: 32px;
  width: 100%;
}
.policyAndCondition {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}
@media only screen and (max-width: 1280px) {
  .container {
    grid-template-columns:
      auto auto
      auto;
    gap: 80px;
  }
}
@media only screen and (max-width: 991px) {
  .container {
    padding: 48px 0;
  }
  .container {
    grid-template-columns: auto auto;
  }
  .copyRightContainer {
    grid-template-columns: 1fr;
    gap: 12px;
  }
  .logoContainer {
    order: -1;
  }
  .policyAndCondition {
    justify-content: center;
  }
  .copyRight {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    grid-template-columns: auto auto;
  }
  .infoContainer {
    grid-column: span 2;
  }
}
@media only screen and (max-width: 520px) {
  .container {
    grid-template-columns: auto;
    gap: 48px;
  }
  .infoContainer {
    grid-column: span 1;
  }
  .link {
    font-size: 18px;
  }
}
