.topHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border-bottom: 1px solid var(--Primitive-100);
  padding-bottom: 14px;
}
.step {
  font-size: 18px;
  color: var(--Primitive-200);
}
.currentStep {
  color: var(--Primitive-Blue-500);
}
.completedStep {
  color: var(--Primitive-Green-500);
}
