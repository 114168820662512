.wrapper {
  background: var(--Primitive-50);
  min-height: 100vh;
  height: 100%;
  z-index: 1;
  position: relative;
  padding-top: 95px;
  overflow: hidden;
}
.outlet {
  padding-top: 95px;
}
.wrapper::before {
  content: "";
  position: fixed;
  top: 0;
  width: 100%;
  height: 280px;
  background: var(--Primitive-950);
  z-index: -1;
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    padding-top: 80px;
  }
  .container {
    width: 100%;
    margin: 0 auto;
  }
}
