.container {
  display: flex;
  flex-direction: column;
  gap: 64px;
  background: var(--Primitive-50);
  padding: 48px;
  border-radius: 8px;
}

.header {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
}
.button {
  margin-left: auto;
}
.hr {
  height: 1px;
  background: var(--Primitive-950);
}
.allNews {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.news {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px;
  border-radius: var(--radius);
  transition: background 0.3s ease, transform 0.3s ease;
}
.news:hover {
  background: var(--Primitive-0);
}

@media only screen and (max-width: 1280px) {
}
@media only screen and (max-width: 1199px) {
  .allNews {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 991px) {
  .container {
    padding: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    gap: 48px;
  }
  .allNews {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 520px) {
  .container {
    padding: 24px;
    gap: 32px;
  }
  .news {
    padding: 20px;
  }
}
@media only screen and (max-width: 399px) {
  .container .heading {
    font-size: 44px;
  }
}
