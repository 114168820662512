.wrapper {
  padding: 24px;
  border-radius: var(--radius);
  background: var(--Primitive-0);
  box-shadow: 0px 1px 2px 0px rgba(14, 15, 57, 0.04);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;
}

.header {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 8px;
}

.tableContainer {
  overflow-x: auto;
  max-height: 100vh;
  overflow-y: auto;
  width: 100%;
}

.table {
  width: 100%;
  border-collapse: collapse;
}
.table th {
  padding: 12px 15px;
  text-align: left;
  border-top: 1px solid var(--Primitive-100);
  border-bottom: 1px solid var(--Primitive-100);
}
.table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid var(--Primitive-200);
}

.table th {
  border-radius: 0px;
  border-top: 1px solid var(--Primitive-100);
  border-bottom: 1px solid var(--Primitive-100);
  background: var(--Primitive-25);
  /* position: -webkit-sticky;
  position: sticky;
  top: -1px; */
  z-index: 1;
}
.heading {
  color: var(--Primitive-600);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;

  white-space: nowrap;
}

.table .textRight {
  text-align: right;
}
.item {
  color: var(--Primitive-800);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  white-space: nowrap;
}
.itemBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.plan {
  padding: 2px 8px;
  border-radius: 4px;
  background: var(--Primitive-Blue-500);
}

.row {
  transition: background-color 0.3s ease;
}
.deposit {
  color: var(--Primitive-Green-500);
}
.type {
  text-transform: capitalize;
}

.withdraw {
  color: #ff4864;
}
.statusContainer {
  display: flex;
  justify-content: flex-end;
}

.status {
  padding: 2px 8px;
  border-radius: 4px;
  text-transform: capitalize;
}
.completed {
  background: #22c55e;
}
.failed {
  background: #ff4864;
}
.pending {
  background: #f59e0b;
}
.active {
  background: var(--Primitive-Green-500);
}

.row:hover {
  background-color: var(--Primitive-200);
  cursor: pointer;
}

.link {
  border-radius: 4px;
  background: var(--Primitive-50);
  padding: 6px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link:hover {
  text-decoration: underline;
}
.arrow {
  font-size: 20px;
  color: var(--Primitive-600);
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 20px 16px;
  }
  .wrapper .tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
}
