.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.header {
  padding: 64px;
  border-radius: var(--radius);
  background: var(--Primitive-0);
  box-shadow: 0px 1px 2px 0px rgba(14, 15, 57, 0.04);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 48px;
  width: 100%;
}
.titleAndInfo {
  max-width: 510px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.needHelp {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.needHelp p {
  white-space: nowrap;
}
.link {
  text-decoration-line: underline;
  white-space: nowrap;
}
@media only screen and (max-width: 991px) {
  .header {
    padding: 48px;
  }
}
@media only screen and (max-width: 767px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .needHelp p {
    font-size: 16px;
  }
  .needHelp {
    flex-direction: row;
    margin: 0;
  }
}
@media only screen and (max-width: 520px) {
  .header {
    padding: 32px;
  }
}
