.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  width: 100%;
}
.card {
  padding: 24px;
  border-radius: var(--radius);
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-shadow: 0 1px 2px 0 rgba(14, 15, 57, 0.04);
  border: 1px solid var(--Primitive-200);
  background: var(--Primitive-0);
  transition: 0.3s;
}
.card:hover {
  border: 1px solid var(--Primitive-Blue-500);
}
.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.type,
.popular {
  padding: 2px 8px;
  border-radius: 4px;
  background: var(--Primitive-Blue-500);
}
.popular {
  background: var(--Primitive-0);
  border: 1px solid var(--Primitive-300);
  margin-left: auto;
  text-transform: uppercase;
}
.dailyInterest {
  font-size: 18px;
  font-weight: 500;
  color: var(--Primitive-800);
}
.button {
  padding: 10px 24px;
}
.depositInfo {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.value {
  margin-left: auto;
  text-transform: capitalize;
}
.active {
  color: var(--Primitive-Green-500);
}
.completed {
  background: #0ad1c8;
}
.failed {
  background: #ff4864;
}
.pending {
  background: #f59e0b;
}

@media only screen and (max-width: 1280px) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 991px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 767px) {
  .cards {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 520px) {
  .card {
    padding: 16px;
  }
  .dailyInterest {
    font-size: 16px;
  }
}
