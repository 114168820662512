.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.header {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
  align-items: flex-end;
  padding: 12px;
  background: var(--Primitive-50);
  border-radius: 6px;
}
.price {
  display: flex;
  align-items: flex-end;
  gap: 6px;
  padding-top: 3px;
}
.howToWithdraw {
  margin-left: auto;
}
.link {
  color: var(--Primitive-Blue-500);
}
.selectContainer {
  display: grid;
  grid-template-columns: auto 1fr;

  gap: 24px;
}
.label {
  margin-top: 12px;
}
.networkContainer,
.amountInputContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}
.info {
  padding: 2px 8px;
  border-radius: 4px;
  background: var(--Primitive-50);
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 150%;
}
.fee {
  color: var(--Primitive-800);
  font-weight: 600;
}
.wallet {
  font-weight: 600;
  font-size: 14px;
  color: var(--Heading-Primary);
}
.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
.inputContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 6px;
}
.input {
  background: var(--Primitive-0);
  border: 1px solid var(--Primitive-200);
}
.amountInputWrapper {
  background: var(--Primitive-0);
  border: 1px solid var(--Primitive-200);
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border-radius: 4px;
}
.amountInputWrapper .amountInput {
  border: none;
  padding: 0;
}
.amountInput input {
  font-size: 16px;
  font-weight: 500;
  color: var(--Primitive-700);
  align-items: center;
}
.amountInput input::placeholder {
  color: var(--Primitive-400);
  opacity: 1;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;

  padding-top: 24px;
  border-top: 1px solid var(--Primitive-200);
  width: 100%;
}
.button {
  color: var(--Primitive-500);
}
@media only screen and (max-width: 767px) {
  .wrapper {
    gap: 16px;
  }
  .selectContainer {
    grid-template-columns: 1fr;
    gap: 12px;
  }
  .addressContainer {
    grid-template-columns: 1fr;
  }
  .generateAddressContainer {
    padding: 12px;
  }
  .amountInput input {
    font-size: 20px;
  }

  .qrCodeContainer {
    align-items: center;
  }
}
