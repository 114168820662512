.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  max-width: 348px;
  width: 100%;
  margin: 0 auto;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.img {
  max-width: 128px;
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .wrapper {
    padding-top: 48px;
  }
}

@media only screen and (max-width: 520px) {
  .img {
    max-width: 96px;
    width: 100%;
  }
}
