.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 350px;
  overflow-y: auto;
}
.cards {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 32px;
  padding: 24px;
  border-radius: var(--radius);
  background: var(--Primitive-0);
  box-shadow: 0px 1px 2px 0px rgba(14, 15, 57, 0.04);
}
.border {
  height: 100%;
  width: 1px;
  background: var(--Primitive-100);
}
.button {
  padding: 0;
  color: var(--Primitive-Blue-500);
  margin-left: auto;
}
@media only screen and (max-width: 767px) {
  .cards {
    grid-template-columns: 1fr;
    gap: 24px;
  }
  .border {
    height: 1px;
    width: 100%;
  }
}
