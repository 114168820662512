.wrapper {
  padding-top: 160px;
  position: relative;
}
.wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 80px);
  background: var(--Primitive-950);
  z-index: -1;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.header {
  max-width: 846px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.steps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}
.step {
  position: relative;
  background: var(--Primitive-0);
  border-radius: 8px;
  padding: 48px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
}
.id {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -35%);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  padding: 8px 16px;
  display: flex;
  align-items: flex-end;
  background: var(--Primitive-Blue-500);
  z-index: 1;
}
.icon {
  display: block;
  max-width: 60px;
  width: 100%;
  margin-bottom: 20px;
}
.getYourInterested {
  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 48px;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 80px;
  padding-bottom: 24px;
}
.imgContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.img {
  max-width: 524px;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .steps {
    gap: 12px;
    grid-template-columns: 1fr 1fr;
    max-width: 846px;
    width: 100%;
    margin: 0 auto;
  }
  .step {
    padding: 24px;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding-top: 64px;
  }

  .steps {
    grid-template-columns: 1fr;
    max-width: 600px;
  }
}
@media only screen and (max-width: 767px) {
  .getYourInterested {
    grid-template-columns: 1fr;
  }
  .infoContainer {
    margin: 0;
    padding: 0;
  }
  .imgContainer {
    justify-content: center;
  }
}
@media only screen and (max-width: 520px) {
  .icon {
    margin: 0;
    max-width: 48px;
  }
}
