.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.header {
  display: flex;
  align-items: center;

  justify-content: space-between;
  gap: 24px;
  width: 100%;
}

.header .dropdown {
  border: none;
  max-width: max-content;
}

.tooltip {
  border-radius: 6px;
  border-top: 1px solid var(--Primitive-100);
  background: var(--Primitive-0);
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-width: 140px;
  width: 100%;
}
.investAmount,
.profitAmount,
.legendItem {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 6px;
  margin-bottom: 3px;
  text-align: right;
}
.investBar {
  border-radius: 12px;
  border-radius: 2px;
  background: var(--Primitive-Blue-500);
  height: 100%;
  display: block;
  width: 3px;
}
.profitBar {
  border-radius: 12px;
  border-radius: 2px;
  background: var(--Primitive-Green-500);
  height: 100%;
  display: block;
  width: 3px;
}
.customLegend {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  gap: 40px;
}

.legendColor {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 50%;
}
@media only screen and (max-width: 520px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .header .dropdown {
    max-width: 100%;
  }
  .customLegend {
    grid-template-columns: max-content;
    gap: 2px;
  }
}
