.wrapper {
  padding: 10px;
  border-radius: var(--radius);
  background: var(--Primitive-50);
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 384px;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 20px;
  padding-bottom: 0;
}

.mobileAndAdress {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
}
.officeInfo {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: var(--Primitive-0);
  border-radius: 6px;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    max-width: 600px;
    margin: 0 auto;
  }
}
