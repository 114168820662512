.content {
  display: flex;
  flex-direction: column;

  max-width: 384px;
  gap: 64px;
  width: 100%;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.helpContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}
.link {
  color: var(--Primitive-Blue-500);
  text-decoration: underline;
  font-weight: 500;
}
.bold {
  font-weight: 700;
}
.steps {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.step {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
}
.stepInfo,
.icon {
  color: var(--Primitive-700);
}
.icon {
  font-size: 22px;
}
.semiActive .stepInfo,
.semiActive .icon {
  color: var(--Primitive-300);
}
.active .stepInfo {
  color: var(--Primitive-0);
}
.active .icon {
  color: var(--Primitive-Green-500);
}

@media only screen and (max-width: 991px) {
  .content {
    max-width: 600px;
    width: 100%;
  }
}
@media only screen and (max-width: 520px) {
}
