.wrapper {
  padding: 24px;
  border-radius: var(--radius);
  background: var(--Primitive-0);
  box-shadow: 0px 1px 2px 0px rgba(14, 15, 57, 0.04);
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;
  margin: 0 auto;
}
.counter {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px 6px;
}
.hr {
  height: 1px;
  width: 100%;
  background: var(--Primitive-100);
}
.infoBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-right: 1px solid var(--Primitive-100);
}
.infoBox:last-child {
  border: none;
}
.currency {
  color: var(--Primitive-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
}
@media only screen and (max-width: 1199px) {
  .counter {
    grid-template-columns: 1fr 1fr;
  }
  .infoBox:nth-child(2) {
    border: none;
  }
}

@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 16px;
  }
  .counter {
    grid-template-columns: 1fr;
  }
  .hr {
    display: none;
  }
  .infoBox {
    border: none;
    border-bottom: 1px solid var(--Primitive-100);
    padding-bottom: 24px;
    width: max-content;
    margin: 0 auto;
  }
  .infoBox:nth-child(2) {
    border-bottom: 1px solid var(--Primitive-100);
  }
  .infoBox:last-child {
  }
}
