.wrapper {
  padding: 160px 0;
  padding-bottom: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.header {
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.heading {
  padding-bottom: 6px;
}
.investorContainer {
  position: relative;
}
.investorContainer::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 160px;
  height: 100%;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  z-index: 2;
}
.investorContainer::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 160px;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  z-index: 2;
}
.investor {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 20px;

  border-radius: 8px;
  transition: background 0.3s ease, transform 0.3s ease;
  width: 160px;
  min-height: 160px;
}

.investor:hover {
  background: var(--Primitive-50);
  opacity: 1;
  transform: translateY(0);
  gap: 16px;
}

.investorLogo {
  max-width: 64px;
  width: 100%;
}

.investorName {
  opacity: 0;
  visibility: hidden;
  padding: 6px 16px;
  border-radius: var(--radius-24, 24px);
  background: var(--Primitive-0);
  box-shadow: 0px 1px 2px 0px rgba(14, 15, 57, 0.04);
  transition: opacity 0.3s ease, visibility 0.3s ease;
  height: 0;
}

.investor:hover .investorName {
  opacity: 1;
  visibility: visible;
  height: auto;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding: 96px 0;
    padding-bottom: 24px;
  }
  .container {
    gap: 32px;
  }
  .investorContainer::before {
    width: 50px;
  }
  .investorContainer::after {
    width: 50px;
  }
}
@media only screen and (max-width: 520px) {
  .investor {
    padding: 12px;
    width: 140px;
    height: auto;
  }
}
