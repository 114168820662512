.wrapper {
  padding-top: 160px;
}
.container {
  background: url(../../../../images/about/aboutHeroImg.png);
  background-position: center;
  background-size: cover;

  border-radius: 6px;
}
.contentWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;
  padding-left: 96px;
  padding-bottom: 50px;
  transform: translateY(-80px);
}
.content {
  padding: 64px;
  padding-top: 0;
  border-radius: var(--radius);
  background: var(--Primitive-0);
  max-width: 512px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.img {
  width: 100%;
  border-radius: 8px;
}
.button {
  padding: 0;
  text-decoration: underline;
  font-size: 16px;
}
@media only screen and (max-width: 1280px) {
  .contentWrapper {
    padding-left: 64px;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding-top: 124px;
  }
  .container {
    width: 100%;
  }
  .contentWrapper {
    flex-direction: column;
    padding: 0 24px;
  }
  .content {
    margin: 0 auto;
    padding: 48px;
    padding-top: 0;
  }
  .button {
    background: var(--Primitive-0);
    padding: 12px 24px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 520px) {
  .contentWrapper {
    padding: 0 16px;
  }
  .content {
    padding: 32px;
    padding-top: 0;
  }
}
