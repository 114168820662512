.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.verifyIdentityWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 334px;
  width: 100%;
  margin: 0 auto;
}
