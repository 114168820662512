.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 348px;
  width: 100%;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.allInput {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.bottomBar {
  display: flex;
  align-items: center;

  width: 100%;
}
.dot {
  color: var(--Primitive-200);
  font-size: 24px;
  cursor: pointer;
}
.dot:hover {
  color: var(--Primitive-Blue-500);
}
.activeDot {
  color: var(--Primitive-Blue-500);
}
.button {
  margin-left: auto;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    max-width: 600px;
    margin: 0 auto;
  }
}
