.wrapper {
  padding-top: 160px;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(114, 115, 157, 0.24);
  background: var(--Primitive-950);
}
.container {
  display: flex;
  flex-direction: column;
  gap: 96px;
}
.header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 32px;
}
.heading {
  max-width: 580px;
  width: 100%;
}
.infoContainer {
  max-width: 377px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.img {
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding-top: 64px;
  }
  .header {
    flex-direction: column;
    align-items: flex-start;
    max-width: 700px;
    width: 100%;
    gap: 24px;
  }
  .container {
    gap: 48px;
  }
  .infoContainer,
  .heading {
    max-width: 100%;
  }
}
