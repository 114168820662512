.dropdown {
  position: relative;
  width: 100%;
  background: var(--Primitive-0);
}
.labelContainer {
  display: flex;
  align-items: center;

  gap: 8px;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 4px;
  border: 1px solid var(--Primitive-200);
}
.logo {
  max-width: 24px;
  width: 100%;
  border-radius: 50%;
  aspect-ratio: 1/-1;
}
.name {
  color: var(--Primitive-950);

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.pair {
  color: var(--Primitive-500);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: uppercase;
}

.arrow {
  font-size: 14px;
  color: var(--Primitive-300);
  cursor: pointer;
  margin-left: auto;
}
.dropdownMain {
  min-width: calc(100% + 2px);
  width: max-content;
  position: absolute;
  right: 0;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 8px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s ease;
  border: 1px solid var(--Primitive-200);
  border-radius: 4px;
  background: var(--Primitive-0);
}

.list {
  height: 0;
  overflow: hidden;
}

.dropdownMain.active {
  opacity: 1;
  pointer-events: all;
}
.active .list {
  overflow: auto;
  max-height: 250px;
  height: 100%;
  opacity: 1;
  pointer-events: all;
}

.listItem {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  padding: 8px 16px;
  cursor: pointer;
  transition: 0.4s ease;
  color: var(--Primitive-700);
  font-size: 14px;
  font-weight: 500;
}

.listItem.active {
  background: var(--Primitive-Blue-500);
}
.listItem.active .name {
  color: var(--Primitive-0);
}
.listItem.active .pair {
  color: var(--Primitive-200);
}
.listItem:hover {
  opacity: 0.8;
}
