.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  color: var(--Primitive-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
}
.inputContainer {
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  border: 1px solid var(--Primitive-200);
  padding: 10px 16px;
  border-radius: 4px;
  background: var(--Primitive-0);
}

.searchIconInputContainer {
  padding: 10px 12px;
}

.searchIcon {
  font-size: 20px;

  margin-top: 2px;
  cursor: pointer;
}
.input {
  color: var(--Primitive-700);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  text-overflow: ellipsis;
  width: 100%;
}
.input::placeholder {
  color: var(--Placeholder);
}
.close {
  color: var(--Text-Placeholder);
  cursor: pointer;
  font-size: 18px;
}
.eye {
  cursor: pointer;
  font-size: 20px;
  color: var(--Primitive-400);
}
