.container {
  display: flex;
  flex-direction: column;
  gap: 64px;
  background: var(--Primitive-50);
  padding: 48px;
  border-radius: 8px;
}

.topHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 48px;
}
.header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  max-width: 580px;
  width: 100%;
}
.facilities {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.facility {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-right: 1px solid var(--Primitive-200);
  padding: 0 40px;
}
.facility:first-child {
  padding-left: 0;
}
.facility:last-child {
  padding-right: 0;
  border: none;
}
.icon {
  font-size: 24px;
  color: var(--Primitive-Blue-500);
  display: block;
  margin-bottom: 10px;
}
@media only screen and (max-width: 1280px) {
  .facility {
    padding: 0 32px;
  }
}
@media only screen and (max-width: 1199px) {
  .facilities {
    grid-template-columns: 1fr 1fr;
    gap: 24px 0;
  }
  .facility {
    border: none;
    padding: 0;
  }
  .facility:nth-child(2) {
    padding-left: 32px;
  }
  .facility:first-child {
    border-right: 1px solid var(--Primitive-200);
    padding-right: 32px;
  }
}
@media only screen and (max-width: 991px) {
  .topHeader {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
  }
  .button {
    margin-left: auto;
  }
  .container {
    gap: 48px;
    padding: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .facilities {
    grid-template-columns: 1fr;
  }

  .facility:nth-child(2),
  .facility:first-child {
    padding: 0;
    padding-bottom: 24px;
    border-right: none;
  }
  .facility {
    border-bottom: 1px solid var(--Primitive-200);
    padding-bottom: 24px;
  }
  .facility:last-child {
    padding: 0;
    border: none;
  }
}
@media only screen and (max-width: 520px) {
  .container {
    padding: 24px;
  }
}
