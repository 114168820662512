.dropdown {
  position: relative;
}

.dropdownMain {
  max-width: 250px;
  min-width: 100%;
  width: max-content;
  height: 0;
  overflow: hidden;
  background-color: var(--Primitive-0);
  border: 1px solid var(--Primitive-200);

  border-radius: 4px;
  position: absolute;
  left: 0%;

  top: calc(100% + 8px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: 0.4s ease;
  color: var(--Primitive-700);
}
.dropdown2 .dropdownMain {
  right: 0;
  left: auto;
}
.dropdownMain.active {
  /* overflow: auto; */
  max-height: 280px;
  height: max-content;
  opacity: 1;
  pointer-events: all;
}
.labelContainer {
  border-radius: 4px;
  border: 1px solid var(--Primitive-200);
  background: var(--Primitive-0);
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.labelContainer2 {
  border-radius: 20px;
  border: none;
  min-width: 148px;
  padding: 8px 14px;
}
.selectedValue {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.searchContainer {
  position: relative;
  padding: 4px 12px;
  border-radius: 4px;
  background: var(--Primitive-50);
  display: flex;
  align-items: center;
  gap: 10px;
}

.searchContainer .searchIcon {
  font-size: 18px;
  color: var(--Primitive-400);
}

.searchContainer input {
  height: 40px;
  border-radius: 10px;

  font-size: 16px;
  width: 100%;
  font-weight: 500;
  background-color: transparent;

  color: var(--Primitive-700);
  outline: none;
}

.searchContainer input::placeholder {
  color: var(--Primitive-400);
  opacity: 1;
}

.list {
  overflow: auto;
}

.listItem {
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  gap: 12px;
  align-items: center;
  transition: 0.4s ease;
  color: var(--Primitive-700);
  border-radius: 4px;
  width: calc(100% - 10px);
  margin: 0 auto;
}

.listItem .left {
  font-size: 16px;
  font-weight: 500;
  color: var(--Primitive-700);
}

.listItem .right {
  font-size: 14px;
  font-weight: 500;
}
.listItem:hover {
  opacity: 0.8;
}
.listItem.active {
  background-color: var(--Primitive-Blue-500);
  color: var(--Primitive-0);
}
.listItem.active:hover {
  opacity: 1;
}
.img {
  max-width: 22px;
  width: 100%;
  border-radius: 50%;
}
.checkmark {
  color: var(--Primitive-0);
  font-size: 16px;
  margin-left: auto;
}
.price {
  margin-left: auto;
}
.arrow {
  color: var(--Primitive-400);
  margin-left: auto;
}
.listItem.active .left {
  color: var(--Primitive-0);
}
