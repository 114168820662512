.wrapper {
  padding: 24px;
  border-radius: var(--radius);
  background: var(--Primitive-0);
  box-shadow: 0px 1px 2px 0px rgba(14, 15, 57, 0.04);
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.hr {
  height: 1px;
  background: transparent;
  border: 1px solid var(--Primitive-100);
}
.currency {
  color: var(--Primitive-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
}
.referralInfo,
.earningsInfo {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.spaceBetween {
  display: flex;

  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.value {
  margin-left: auto;
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 16px;
  }
}
