.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 348px;
  width: 100%;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.allInput {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.messageContainer,
.dropdownContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  color: var(--Primitive-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
}
.message {
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  border: 1px solid var(--Primitive-200);
  padding: 10px 16px;
  border-radius: 4px;
  background: var(--Primitive-0);
}
.message {
  color: var(--Primitive-700);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  text-overflow: ellipsis;
  width: 100%;
  outline: none;
  width: 100%;
}

.bottomBar {
  display: flex;
  align-items: center;

  width: 100%;
}
.dot {
  color: var(--Primitive-200);
  font-size: 24px;
  cursor: pointer;
}
.dot:hover {
  color: var(--Primitive-Blue-500);
}
.activeDot {
  color: var(--Primitive-Blue-500);
}
.button {
  margin-left: auto;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    max-width: 600px;
    margin: 0 auto;
  }
}
