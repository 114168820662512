.methods {
  display: flex;
  flex-direction: column;
}

.method {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 14px;
  padding: 18px 0;
  border-bottom: 1px solid var(--Primitive-100);
  background: var(--Primitive-0);
}
.method:first-child {
  border-top: 1px solid var(--Primitive-100);
}

.icon {
  max-width: 26px;
  width: 100%;
}
.methodTitle {
  text-align: left;
}
.arrow {
  font-size: 20px;
  color: var(--Primitive-300);
  margin-left: auto;
}
