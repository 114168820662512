.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.header {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
  align-items: flex-end;
  padding: 12px;
  background: var(--Primitive-50);
  border-radius: 6px;
}
.note {
  color: var(--Primitive-800);
  font-weight: 500;
}
.qrCodeWrapper {
  display: grid;
  grid-template-columns: 128px 1fr;
  grid-template-rows: auto auto;
  align-items: center;
  gap: 24px;
}
.qrCode {
  padding: 8px;
  border-radius: 6px;
  border: 1px solid var(--Primitive-100);
  grid-row: 1/-1;
}
.addressContainer {
  padding: 10px 16px;
  border-radius: 4px;
  border: 1px solid var(--Primitive-100);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.address {
  max-width: 192px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.copyButton {
  color: var(--Primitive-400);
  font-size: 18px;
  margin-top: 4px;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 4px;

  padding-top: 24px;
  border-top: 1px solid var(--Primitive-200);
  width: 100%;
}
.button {
  color: var(--Primitive-500);
}
@media only screen and (max-width: 767px) {
  .wrapper {
    gap: 16px;
  }
  .qrCode {
    max-width: 128px;
    width: 100%;
    margin: 0 auto;
  }
  .qrCodeWrapper {
    grid-template-columns: 1fr;
  }
}
