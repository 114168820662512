@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
}
:root {
  --Primitive-Blue-500: #4669f5;
  --Primitive-Green-500: #22c55e;
  --Secondary: #22234d;
  --Primitive-0: #fff;
  --Primitive-50: #f6f6f9;
  --Primitive-25: #fafafd;
  --Primitive-100: #f0f0f5;
  --Primitive-200: #e5e5ed;
  --Primitive-300: #cacadf;
  --Primitive-400: #9495bf;
  --Primitive-500: #72739d;
  --Primitive-600: #5e5f89;
  --Primitive-800: #363761;
  --Primitive-700: #4a4b75;
  --Primitive-900: #1b1b27;
  --Primitive-950: #0e0f39;
  --radius: 8px;
  --Placeholder: #9495bf;

  --Error: #ff4864;
  --Warning: #ffb729;
  --Success: #24c788;
}
#root {
  min-height: 100vh;
}
body {
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background: var(--Primitive-0);
}
.container {
  max-width: 1280px;
  width: 90%;
  margin: 0 auto;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}
input {
  border: none;
  outline: none;
  background: transparent;
}
button,
a {
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  background: transparent;
}
button:hover,
a:hover {
  opacity: 0.8;
  transform: translateY(-3px);
}
button:active {
  transform: translateY(0px);
}
input {
  line-height: 100%;
}

::-webkit-scrollbar {
  width: 4px;
  background: rgba(250, 250, 250, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: var(--Base-50);
  border-radius: 4px;
}
.overflow {
  scrollbar-width: thin;
  scrollbar-color: var(--Primitive-Blue-500) rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari browsers */
.overflow::-webkit-scrollbar {
  width: 3px;
  border-radius: 5px;
}
.overflow::-webkit-scrollbar-track {
  border-radius: 5px;
  width: 3px;
}

.overflow::-webkit-scrollbar-thumb {
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
  width: 3px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.highlight {
  color: var(--Primary-Default);
}
input::placeholder {
  color: var(--Placeholder);
  opacity: 0.8;
}
.highlight {
  color: var(--Primitive-Blue-500);
}
.swiper-scrollbar {
  display: none !important;
}
.swiper-slide {
  height: auto !important;
}

.swiper-pagination-bullet-active {
  background: var(--Primitive-Blue-500) !important;
}
.recharts-rectangle {
  pointer-events: none !important;
}
.tradingview-widget-container .tv-widget-chart--with-border {
  border: none !important;
  padding: 10px;
}

.tradingview-widget-container__widget {
  /* Custom styles for the widget element */
}

.tradingview-widget-copyright {
  font-size: 12px; /* Adjust font size for the copyright text */
}
/* news widgert */
