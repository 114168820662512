.wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  align-items: start;
}
.container {
  max-width: 305px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    display: block;

    max-width: 800px;
    width: 100%;
    margin: 0 auto;
  }
  .container {
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
  }
}
