.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  padding-top: 48px;
  padding-left: 48px;
  border-radius: 8px;
  border: 8px solid var(--Primitive-50);
  background: var(--Primitive-50);
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.button {
  margin-top: 12px;
}
.features {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.feature {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
}
.checkmark {
  color: var(--Primitive-Blue-500);
  font-size: 24px;
  margin-top: 2px;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.imgContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.img {
  max-width: 544px;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .container {
    grid-template-columns: 1fr;
    padding: 16px;
  }
  .header {
    gap: 12px;
  }
  .infoContainer {
    gap: 32px;
  }
  .features {
    gap: 24px;
  }
  .feature {
    gap: 12px;
  }
  .imgContainer {
    justify-content: center;
  }
}
