.wrapper {
  padding: 160px 0;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 846px;
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .wrapper {
    padding: 96px 0;
  }
  .container {
    gap: 48px;
  }
}
