.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 348px;
  width: 100%;
  margin: 0 auto;
}
.wrapper .button {
  padding: 0;
  color: var(--Primitive-Blue-500);
}
