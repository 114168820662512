.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  max-width: 348px;
  width: 100%;
  margin: 0 auto;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.qrCode {
  padding: 8px;
  border-radius: 6px;
  border: 1px solid var(--Primitive-100);
  max-width: 184px;
  width: 100%;
  margin: 0 auto;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}
.cancelButton {
  color: var(--Primitive-400);
  padding: 0;
}

@media only screen and (max-width: 991px) {
  .wrapper {
    padding-top: 48px;
  }
}

@media only screen and (max-width: 520px) {
  .img {
    max-width: 96px;
    width: 100%;
  }
}
