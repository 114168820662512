.wrapper {
  width: 100%;

  top: 95px;
  background: var(--Primitive-950);
  z-index: 99;
  position: fixed;
}
.navbar {
  height: 60px;
  display: flex;
  justify-content: space-between;
  gap: 18px;
  align-items: center;
  padding: 14px 0;
  border-top: 1px solid #22234d;
  border-bottom: 1px solid #22234d;
}
.forMobile {
  display: none;
}

.navItems {
  display: flex;
  align-items: center;
  gap: 28px;
  width: 100%;
}
.navItem {
  color: var(--Primitive-400);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.navActive {
  color: var(--Primitive-0);
}
.myAccount {
  margin-left: auto;
  display: block;
}
.iconContainer {
  margin-left: auto;
  position: relative;
}
.icon {
  font-size: 18px;
  color: var(--Primitive-400);
  display: none;
  cursor: pointer;
}
.close {
  top: 20px;
  right: 20px;
  position: fixed;
  z-index: 200;
  font-size: 24px;
  color: var(--Primitive-400);
}
@media only screen and (max-width: 1199px) {
  .navbar {
    cursor: pointer;
  }
  .wrapper {
    top: 80px;
  }
  .forMobile {
    display: block;
  }
  .navItems {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 24px;
    gap: 0px;
    min-height: 100vh;
    background: var(--Primitive-950);
    transform: translateX(250%);
    transition: 0.3s;
    position: fixed;
    top: 0;
    left: auto;
    right: 0;
    top: 60px;
    padding-top: 70px;
    width: calc(100%);
    max-width: 320px;
    min-height: 100vh;
    border: none;
    backdrop-filter: blur(0px);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    gap: 20px;
    z-index: 2;
  }

  .sidebar {
    transform: translateX(0%);
    right: 0;
  }
  .icon {
    display: block;
  }
  .myAccount {
    margin: 0;
  }
}
