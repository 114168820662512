.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.chart {
  border-radius: 6px;
  border: 1px solid var(--Primitive-100);
  background: var(--Primitive-0);
  padding: 12px;
}
.investAndProfit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}
.invest {
  color: var(--Primitive-Green-500);
}
.profit {
  color: var(--Primitive-Blue-500);
}
.investAmount,
.profitAmount,
.legendItem {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 6px;
  margin-bottom: 3px;
  text-align: right;
}
.type2 .investAmount,
.type2 .profitAmount {
  gap: 10px;
  grid-template-columns: auto 1fr auto auto;
  border: 1px solid var(--Primitive-100);
  padding: 10px 12px;
}

.type2 .profitAmount {
  border-top: none;
  margin-top: -2px;
}
.investBar {
  border-radius: 12px;
  border-radius: 2px;
  background: var(--Primitive-Blue-500);
  height: 100%;
  display: block;
  width: 3px;
}
.profitBar {
  border-radius: 12px;
  border-radius: 2px;
  background: var(--Primitive-Green-500);
  height: 100%;
  display: block;
  width: 3px;
}
.customLegend {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  gap: 40px;
}

.legendColor {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 50%;
}
.profitWrapper,
.investWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.withdrawButton {
  color: var(--Primitive-Blue-500);
  font-size: 16px;
  padding: 0;
}
@media only screen and (max-width: 520px) {
  .type2 .investAmount,
  .type2 .profitAmount {
    gap: 10px;
    grid-template-columns: auto 1fr auto;
    border: 1px solid var(--Primitive-100);
    padding: 10px 12px;
  }
  .withdrawButton {
    grid-column: 1/-1;
    text-align: center;
    background: var(--Primitive-Blue-500);
    padding: 2px 8px;
    color: var(--Primitive-0);
  }
}
