.wrapper {
  border: 1px solid var(--Primitive-100);
  border-radius: 4px;
}

.infoBox {
  padding: 12px 16px;
  border-bottom: 1px solid var(--Primitive-100);
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 160px auto;
  gap: 12px;
}
.availabilityContainer {
  border-bottom: mpme;
}
.userImgBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.userImgContainer {
  position: relative;
  cursor: pointer;
}
.userImg {
  max-width: 60px;
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 50%;
}
.closeIcon {
  max-width: 20px;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(10%);
  z-index: 1;
}
.wrapper .editButton,
.wrapper .editedButton {
  padding: 0px;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  min-height: 100%;
}
.wrapper .editedButton {
  background: var(--Primitive-Blue-500);
}

.editButton:hover,
.editedButton:hover {
  background: var(--Primitive-Blue-500);
  opacity: 1;
  transform: translateY(0);
}
.editIcon,
.checkedIcon {
  font-size: 18px;
  color: var(--Primitive-Blue-500);
}
.checkedIcon {
  color: var(--Primitive-0);
}
.editButton:hover .editIcon,
.editedButton:hover .checkedIcon {
  color: var(--Primitive-0);
}
.input {
  max-width: 180px;
  padding: 8px 14px;
}
.input input {
  font-size: 14px;
}
.wrapper .dropdown {
  padding: 8px 14px;
  font-size: 14px;
}
.availability {
  padding: 2px 8px;
  font-size: 14px;
  margin-left: auto;
  border-radius: 4px;
  font-weight: 600;
}
.available {
  background: var(--Primitive-Green-500);
  color: var(--Primitive-0);
}
.notAvailable {
  background: #ff4864;
  color: var(--Primitive-0);
}
