.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.plan {
  padding: 2px 8px;
  border-radius: 4px;
  background: var(--Primitive-Blue-500);
  margin-left: auto;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.value {
  margin-left: auto;
  text-transform: capitalize;
}
.active {
  color: var(--Primitive-Green-500);
}
.completed {
  background: #0ad1c8;
}
.failed {
  background: #ff4864;
}
.pending {
  background: #f59e0b;
}
