.wrapper {
  padding: 160px 0;
}

.topHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  flex-wrap: wrap;
  gap: 32px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  max-width: 564px;
  width: 100%;
}
.nextAndPrevButton {
  display: flex;
  align-items: center;
  margin-left: auto;

  gap: 16px;
}
.prevButton:hover,
.nextButton:hover {
  transform: translate(0);
}

.arrow {
  font-size: 36px;
  color: var(--Primitive-300);
}
.activeButton .arrow {
  color: var(--Primitive-700);
}

.container {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: var(--radius-8, 8px);
  border: var(--stroke-0, 1px) solid var(--Primitive-200);
  background: var(--Primitive-0);

  box-shadow: 0px 8px 12px -2px rgba(14, 15, 57, 0.06),
    0px 4px 3px -1px rgba(14, 15, 57, 0.02);
  padding: 24px;
  height: 100%;
}
.quotationIcon {
  max-width: 48px;
}
.authorInfo {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  margin-top: auto;
}
.authorImg {
  max-width: 48px;
  width: 100%;
  border-radius: 50%;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    padding: 96px 0;
  }
  .container {
    gap: 48px;
  }
  .arrow {
    font-size: 30px;
  }
}
@media only screen and (max-width: 520px) {
  .container {
    gap: 32px;
  }
  .quotationIcon {
    max-width: 36px;
  }
}
