.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.xl2 {
  padding-top: 8px;
}
.info {
  line-height: 155%;
}
.bold {
  font-weight: 600;
}
